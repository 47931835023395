import { FC, useState } from 'react'

import { LockClosedIcon } from '@heroicons/react/24/outline'
import { HUBSPOT_FORM_ID } from 'api/src/common/enums'

import { navigate, routes } from '@redwoodjs/router'

import HubspotForm from 'src/components/HubspotForm/HubspotForm'
import Modal from 'src/components/Modal/Modal'

import Button from '../Button/Button'

const FeatureDenied: FC<{
  embedUrl?: string
  hubspotFormId?: HUBSPOT_FORM_ID
}> = ({ embedUrl, hubspotFormId }) => {
  const [moreInfoModalOpen, setMoreInfoModalOpen] = useState<boolean>(false)

  return (
    <div className="max-h-[calc(100vh-65px)] h-full w-full grid place-items-center p-4">
      <div className="w-full max-w-7xl w-4/6">
        <div className=" w-full grid gap-6 place-items-center">
          {!embedUrl && (
            <div className="bg-indigo-200 p-4 rounded-full">
              <LockClosedIcon className="w-16 h-16 text-indigo-600" />
            </div>
          )}
          <h1 className="text-2xl font-semibold mb-6">
            This feature is not enabled
          </h1>
        </div>
        {embedUrl && (
          <iframe
            src={embedUrl}
            className="bg-blue-100 rounded-lg shadow-lg aspect-video w-full"
            allowFullScreen
            title="Embedded content"
          />
        )}
        <div className="flex flex-col gap-3 max-w-[250px] mx-auto mt-6">
          {hubspotFormId && (
            <Button onClick={() => setMoreInfoModalOpen(true)}>
              Find out more
            </Button>
          )}
          <Button variant="outlined" onClick={() => navigate(routes.home())}>
            Go Home
          </Button>
        </div>
      </div>
      {hubspotFormId && (
        <Modal
          open={moreInfoModalOpen}
          onClose={() => setMoreInfoModalOpen(false)}
          dialogClassName="p-6 max-w-3xl"
        >
          <HubspotForm hubspotFormId={hubspotFormId} />
        </Modal>
      )}
    </div>
  )
}

export default FeatureDenied
