import React, { FC, useEffect } from 'react'

import { HUBSPOT_FORM_ID } from 'api/src/common/enums'

const HubspotForm: FC<{ hubspotFormId: HUBSPOT_FORM_ID }> = ({
  hubspotFormId,
}) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @ts-expect-error not typed
      if (window.hbspt) {
        // @ts-expect-error not typed
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '8818365',
          formId: hubspotFormId,
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <div>
      <div id="hubspotForm" data-testid="hubspot-form"></div>
    </div>
  )
}

export default HubspotForm
